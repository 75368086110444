import { Component, Host, Prop, Element, Event, EventEmitter, h } from '@stencil/core';

@Component({
  tag: 'x-select-option',
  styleUrl: 'x-select-option.sass',
})
export class XSelectOption {
  @Element() el: HTMLElement;

  @Prop({ reflect: true }) caption: string;
  @Prop({ reflect: true }) chip: string;
  @Prop({ reflect: true }) value: any;
  @Prop({ reflect: true, attribute: 'defaultSelected' }) defaultSelected: boolean = false;
  @Prop({ reflect: true }) toggleAll: boolean = false;
  @Prop({ reflect: true, mutable: true }) selected: boolean = false;
  @Prop() multiple: boolean = false;
  @Prop() autocomplete: boolean = false;

  @Event() update: EventEmitter;

  private emit = ({ initial }) => {
    const detail = {
      initial,
      value: this.value,
      label: this.el.innerText.trim(),
      caption: undefined,
      selected: this.selected,
      toggleAll: this.toggleAll,
      chip: this.chip,
    };

    if (this.caption) {
      detail.caption = this.caption.trim();
    }

    this.update.emit(detail);
  };

  private triggerChange = () => {
    if (this.multiple || !this.selected) {
      this.selected = !this.selected;
    }

    this.emit({ initial: false });
  };

  private handleClick = () => {
    const onbeforechange = this.el.getAttribute('onbeforechange');

    if (typeof onbeforechange === 'string') {
      try {
        const onBeforeChange = eval(onbeforechange) as any;
        const isPromise = typeof onBeforeChange === 'object' && typeof onBeforeChange.then === 'function';

        if (isPromise) {
          onBeforeChange.then(this.triggerChange).catch(() => {});
        } else {
          this.triggerChange();
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      this.triggerChange();
    }
  };

  private handleEnter = e => {
    const keyCode = e.keyCode || e.charCode;

    if (keyCode === 13) {
      this.handleClick();
    }
  };

  componentDidLoad() {
    setTimeout(() => {
      if (this.selected) {
        this.emit({
          initial: true,
        });
      }
    });
  }

  render() {
    return (
      <Host class="x-select-option" onClick={this.handleClick} onKeyDown={this.handleEnter} tabindex="-1">
        {this.multiple && (
          <span class="x-select-option__control">
            <x-checkbox checked={this.selected}></x-checkbox>
          </span>
        )}
        <span class="x-select-option__value">
          <slot></slot>
        </span>
      </Host>
    );
  }
}
