.x-select-option
  +preset(3)
  display: flex
  cursor: pointer
  padding: 6px 15px
  color: var(--on-input-white-20)
  outline: none
  mark
    font-style: normal
    font-weight: 600
    color: var(--on-input-green)
    background: none
  &[selected]
    color: var(--on-input-green)
  &[hidden],
  &[custom]
    display: none
  &:hover,
  &:focus
    color: var(--on-input-hover-green)
  &__control
    flex: 0 0 auto
    margin-right: 12px
